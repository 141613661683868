@use 'web/common/normalize';

// Tippy
@use 'tippy.js/dist/tippy.css';
@use 'tippy.js/animations/shift-away.css';

/* You can add global styles to this file, and also import other style files */
@use 'web/main';

.cdk-overlay-container {
  z-index: 10001; // higher amount than modal
}

@media print {
  main {
    // main pushes the modal we want to print to the bottom, so we hide it
    // only for portfolio, since we need it in workspace export
    display: none;
  }
}
